"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.License = void 0;
var License;
(function (License) {
    License["treeView"] = "treeView";
    License["i18n"] = "i18n";
    License["configurableTable"] = "configurableTable";
    License["richTextEditor"] = "richTextEditor";
    License["imageCropper"] = "imageCropper";
    License["fileUpload"] = "fileUpload";
    License["freeJSON"] = "freeJSON";
    License["extendedEditForms"] = "extendedEditForms";
    License["extendedNavigation"] = "extendedNavigation";
    License["tooltips"] = "tooltips";
    License["integratedHTML"] = "integratedHTML";
    License["extendedTable"] = "extendedTable";
    License["outgoingReferenceFilters"] = "outgoingReferenceFilters";
    License["incomingReferenceFilters"] = "incomingReferenceFilters";
    License["standardFilter"] = "standardFilter";
    License["responsiveViews"] = "responsiveViews";
})(License = exports.License || (exports.License = {}));
