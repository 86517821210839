import React, { useContext, useEffect } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { createStyles, makeStyles } from '@mui/styles'
import {
  IconButton,
  Theme,
  Box, Typography, Paper, FormControlLabel, Radio, useTheme,
} from '@mui/material'
import { Link } from 'react-router-dom'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { CrudeContext } from '@3m5/crude-frontend/dist/crudeHooks'
import {
  LanguageType,
} from '@3m5/crude-frontend/dist/shared/types/configurationTypes'
import Playground from '../../../screens/App/screens/Playground'
import { useSelector } from 'react-redux'
import { getLogo } from '../../../store/settings/settings.selectors'
import Sidebar from './Sidebar'

interface Props {
  setShowPlayground: (value: boolean) => void
    doLogout: () => void
    user: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      float: 'right',
      cursor: 'pointer',
      marginRight: '8px',
    },
    button: {
      margin: '8px',
    },
    grow: {
      flexGrow: 1,
    },
    language: {
      margin: '8px',
      width: '60px',
      padding: '1px',
      '&:before': {
        borderBottom: '0px',
      },
    },
    dialogPaperStyle: {
      width: '600px',
      height: '600px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    dialogTitle: {
      minHeight: theme.spacing(3),
    },
    toolBar: {
      background: theme.palette.background.default,
      top: '0px',
      height: '65px',
      position: 'fixed',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)',
      zIndex: 1000,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    languageContainer: {
      marginRight: '10px',
      position: 'relative',
    },
    margin: {
      margin: '8px',
    },
    activeElement: {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      marginTop: theme.spacing(1),
    },
    activeIcon: {
      color: theme.palette.primary.main,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    disabledPlainText: {
      color: theme.palette.text.disabled,
    },
    activeBorder: {
      border: `3px solid ${theme.palette.primary.main}`,
      padding: '5px',
      height: '100%',
    },
    higherActiveBorder: {
      border: `3px solid ${theme.palette.primary.main}`,
      padding: '12px',
      height: '100%',
    },
    inactiveElement: {
      padding: '8px',
    },
    higherInactiveElement: {
      padding: '15px',
    },
    formatIcon: {
      cursor: 'pointer',
      border: `1px solid ${theme.palette.grey.A400}`,
      borderRadius: '2px',
      padding: '3px',
      margin: '3px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inactiveFormatIcon: {
      opacity: '0.5',
    },
    image: {
      height: '55px',
    },
    icon: {
      width: '10px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
    },
    logo: {
      marginLeft: '70px',
      position: 'absolute',
    },
  }),
)

const Header = (props: Props) => {
  const { methods } = useContext(CrudeContext)
  const logo = useSelector(getLogo)
  const theme: Theme = useTheme()

  const languageList = [
    {
      value: 'de-DE',
      label: 'DE',
    },
    {
      value: 'en-US',
      label: 'EN',
    },
    {
      value: 'it-IT',
      label: 'IT',
    },
    {
      value: 'es-ES',
      label: 'ES',
    },
    {
      value: 'fr-FR',
      label: 'FR',
    },
    {
      value: 'pt-PT',
      label: 'PT',
    },
    {
      value: 'pl-PL',
      label: 'PL',
    },
    {
      value: 'ru-RU',
      label: 'RU',
    },
    {
      value: 'tr-TR',
      label: 'TR',
    },
    {
      value: 'sv-SV',
      label: 'SV',
    },
    {
      value: 'uk-UK',
      label: 'UK',
    },
    {
      value: 'ro-RO',
      label: 'RO',
    },
    {
      value: 'no-NO',
      label: 'NO',
    },
    {
      value: 'nl-NL',
      label: 'NL',
    },
    {
      value: 'hu-HU',
      label: 'HU',
    },
    {
      value: 'hr-HR',
      label: 'HR',
    },
    {
      value: 'fi-FI',
      label: 'FI',
    },
    {
      value: 'el-EL',
      label: 'EL',
    },
    {
      value: 'da-DA',
      label: 'DA',
    },
    {
      value: 'cs-CS',
      label: 'CS',
    },
  ]

  const [language, setLanguage] = React.useState(languageList[0].value)
  const [showLanguageSelection, setShowLanguageSelection] = React.useState<boolean>(false)
  const [showEditLayout, setShowEditLayout] = React.useState<boolean>(JSON.parse(window.localStorage.getItem('showPlayground')) || false)
  const [showSideBar, setShowSideBar] = React.useState<boolean>(false)

  useEffect(
    () => {
      window.localStorage.setItem('showPlayground', `${showEditLayout}`)
      props.setShowPlayground(showEditLayout)
    },
    [showEditLayout],
  )

  useEffect(
    () => {
      localStorage.setItem('language', language)
      methods?.changeLanguage(language as LanguageType)
      i18n.use(initReactI18next)
        .init({ lng: language })
    },
    [language],
  )

  const classes = useStyles()
  return (
    <>
      <Box className={classes.toolBar} style={{ width: showEditLayout ? 'calc(100% - 500px)' : '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <IconButton onClick={() => {
            setShowSideBar(!showSideBar)
          }}
          >
            <MenuIcon sx={{ height: '30px', width: '30px' }} />
          </IconButton>
          <Link to='/' className={classes.logo}>
            <div>
              <img
                src={logo}
                className={classes.image}
                alt=''
              />
            </div>
          </Link>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div className={classes.languageContainer}>
            <Box
              onClick={() => setShowLanguageSelection(!showLanguageSelection)}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                border: `1px solid ${theme.palette.grey.A700}`,
                cursor: 'pointer',
                padding: '16px',
                borderRadius: '4px',
              }}
            >
              <Typography>{languageList.find(elem => elem.value === language)?.label}</Typography>
              <ArrowDropDownIcon />
            </Box>
            <Paper
              sx={{
                position: 'absolute',
                padding: '16px',
                borderRadius: '4px',
                display: showLanguageSelection ? 'flex' : 'none',
                width: '350px',
                flexWrap: 'wrap',
                right: '-40px',
              }}
            >
              {languageList.map((option: { value: string, label: string }, i: number) =>
                <FormControlLabel
                  sx={{ width: '74px' }}
                  key={i}
                  value={option.value}
                  control={
                    <Radio
                      checked={language === option.value}
                      onChange={() => {
                        setShowLanguageSelection(false)
                        setLanguage(option.value)
                      }}
                    />
                  }
                  label={option.label}
                />,
              )}
            </Paper>
          </div>
          <IconButton
            sx={{ display: { xs: 'none', md: 'block' } }} onClick={() => {
              setShowEditLayout(!showEditLayout)
            }}
          >
            <ColorLensIcon sx={{ height: '40px', width: '40px' }} />
          </IconButton>
        </Box>
      </Box>

      <Playground showPlayground={showEditLayout} setShowPlayground={setShowEditLayout} />
      <Sidebar
        showSidebar={showSideBar}
        setShowSidebar={(value) => {
          setShowSideBar(value)
        }}
        user={props.user}
        doLogout={
          () => props.doLogout()
        }
      />
    </>
  )
}

export default Header
