"use strict";
/**
 * getCrudeEntityColumns(crudeConfiguration: CrudeConfiguration, crudeSchema: CrudeSchema, entity: string)
 * Liefert ein Array zurück in dem alle Columns enthalten sind mit den jeweiligen Eigenschaften für diese Entity
 * Wenn vom Parent Columns in der crudeConfiguration mitgegeben werden, werden diese verwendet.
 * Dabei wird aber geprüft, ob der User die Berechtigung hat, diese columns zu sehen, ansonsten werden sie ignoriert.
 * Ansonsten werden die columns aus dem Schema generiert und zurückgegeben.
 * nicht verwendete columns aus dem Schema werden vor der letzten pinnedColumn eingefügt
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var getCrudeEntityColumns = function (crudeConfiguration, crudeSchema, entity) {
    var _a, _b, _c;
    var license = ((_b = (_a = crudeConfiguration.crudeLicense) === null || _a === void 0 ? void 0 : _a.configurableTable) === null || _b === void 0 ? void 0 : _b.available) || false;
    var entityColumns = [];
    var entitySchema = crudeSchema.find(function (schema) { return schema.name === entity; });
    // hier werden alle columns aus der CrudeConfiguration extrahiert
    if (license && crudeConfiguration.tabs) {
        var entityConfig = crudeConfiguration.tabs.find(function (tab) { return tab.entity === entity; });
        (_c = entityConfig === null || entityConfig === void 0 ? void 0 : entityConfig.columns) === null || _c === void 0 ? void 0 : _c.forEach(function (column) {
            // Prüfung, ob dieser column im Schema enthalten ist
            var schemaColumn = entitySchema === null || entitySchema === void 0 ? void 0 : entitySchema.columns.find(function (col) { return col.name === column.name; });
            if ((schemaColumn && !(schemaColumn === null || schemaColumn === void 0 ? void 0 : schemaColumn.hideInTableView)) || (column.name === 'crudeAction' && ((entitySchema === null || entitySchema === void 0 ? void 0 : entitySchema.entityPermissions.deleteAllowedByDefault) || (entitySchema === null || entitySchema === void 0 ? void 0 : entitySchema.entityPermissions.updateAllowedByDefault)))) {
                entityColumns.push(__assign(__assign({}, column), { hideInTableView: column.hideInTableView }));
            }
        });
    }
    // hier werden alle columns aus dem Schema generiert und ggf. vor der letzten pinnedColumn angefügt, falls noch nicht verfügbar
    // index finden zwischen den pinnedColumns
    var index = 0;
    entityColumns.forEach(function (col, i) {
        if (!col.pinned) {
            index = i;
        }
    });
    entitySchema === null || entitySchema === void 0 ? void 0 : entitySchema.columns.forEach(function (column) {
        var existingColumn = entityColumns.find(function (col) { return col.name === column.name; });
        if (!existingColumn && !column.hideInTableView) {
            index = index + 1;
            entityColumns.splice(index, 0, {
                name: column.name,
            });
        }
    });
    // wenn noch keine ActionsColumn an letzter Stelle angefügt ist, hier noch erweitern
    var actionColumn = entityColumns.find(function (col) { return col.name === 'crudeAction'; });
    if (!actionColumn) {
        entityColumns.push({
            name: 'crudeAction',
            hideInTableView: false,
            pinned: true,
        });
    }
    return entityColumns;
};
exports.default = getCrudeEntityColumns;
