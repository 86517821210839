{
    "crude": {
        "search": "Suchen",
        "actions": "Aktionen",
        "nextPage": "Nächste Seite",
        "lastPage": "Vorherige Seite",
        "back": "Zurück",
        "empty": "leer",
        "edit": "bearbeiten",
        "create": "erstellen",
        "data": "Datensatz",
        "view": "anzeigen",
        "delete": "Löschen",
        "save": "Speichern",
        "cancel": "Abbrechen",
        "noContent": "Keine Einträge vorhanden. ",
        "resetFilter": "Setzen Sie die Filter zurück und mehr Inhalte anzuzeigen!",
        "createOwnTextField": "Eigenes Textfeld anlegen",
        "textFieldName": "Textfeldbezeichnung",
        "textFieldValue": "Inhalt",
        "insertTextFieldLabel": "Bitte Textfeldbezeichnung eingeben!",
        "deleteTextField": "Textfeld löschen",
        "success": "Speichern war erfolgreich",
        "invalidDataFields": "Die folgenden Datenfelder sind invalid:  ",
        "entries": "Einträge",
        "entry": "Eintrag",
        "tabDetail": "Details",
        "entity": "Entität",
        "of": "von",
        "uploadNewFile": "Datei hochladen",
        "upload": "hochladen",
        "confirm": "Ok",
        "deleteFileQuestion": "Wollen Sie diese Datei wirklich löschen? Der Vorgang kann nicht rückgangig gemacht werden.",
        "deleteFileQuestionTitle": "Achtung!",
        "deleteEntityQuestion": "Wollen Sie diesen Eintrag wirklich löschen? Der Vorgang kann nicht rückgangig gemacht werden.",
        "deleteEntityQuestionTitle": "Achtung!",
        "errorLargeFileUpload": "Die gewählte Datei ist zu groß!",
        "useCroppedImage": "Bildausschnitt verwenden",
        "useOriginalImage": "Originalbild verwenden",
        "available": "verfügbare",
        "searchColumn": "Spalte finden",
        "manageColumns": "Spalten verwalten",
        "hideColumn": "Spalte verbergen",
        "pinToRight": "Spalte rechts anheften",
        "pinToLeft": "Spalte links anheften",
        "sortAsc": "Aufsteigend sortieren",
        "sortDesc": "Absteigend sortieren",
        "hideAll": "Alles verbergen",
        "showAll": "Alles anzeigen",
        "preview": "Vorschau",
        "title": "Titel",
        "description": "Beschreibung",
        "tooltip": "Tooltip",
        "sort": "Sortieren",
        "settings": "Einstellungen",
        "incomingReference": "Referenz",
        "language": "Sprache",
        "deleteTabQuestionTitle": "Achtung!",
        "deleteTabQuestion": "Soll dieser Tab wirklich gelöscht werden? Alle Einstellungen zu diesem Tab werden dabei zurück gesetzt!",
        "deleteElementQuestionTitle": "Achtung!",
        "deleteElementQuestion": "Soll dieses Element wirklich gelöscht werden? Alle Einstellungen zu dieser Spalte werden dabei zurück gesetzt!",
        "configurationEditorDescriptionStringLayout": "Bei dieser Spalte können drei Varianten zur Eingabe der Texte gewählt werden:",
        "configurationEditorDescriptionEnumLayout": "Bei dieser Spalte können zwei Varianten zur Auswahl der Elemente gewählt werden:",
        "configurationEditorDescriptionBooleanLayout": "Bei dieser Spalte können zwei Varianten zur Auswahl der Elemente gewählt werden:",
        "configurationEditorDescriptionForeignEntityLayout": "Bei dieser Spalte können zwei Varianten zur Auswahl der Elemente gewählt werden:",
        "stringLayoutsingleline": "Einzeilig",
        "stringLayoutmultiline": "Mehrzeilig",
        "stringLayouteditor": "RichTextEditor",
        "enumLayoutradio": "Radiobutton",
        "enumLayoutdropdown": "Dropdown",
        "booleanLayoutcheckbox": "Checkbox",
        "booleanLayoutswitch": "Switch",
        "foreignEntityLayoutpopUp": "PopUp",
        "foreignEntityLayoutautocomplete": "Dropdown",
        "foreignEntityLayoutlist": "Liste",
        "formatTooltipbold": "Fett",
        "formatTooltipitalic": "Kursiv",
        "formatTooltipunderline": "Unterstrichen",
        "formatTooltipstrike": "Durchgestrichen",
        "formatTooltipclean": "Formatierung löschen",
        "formatTooltipcodeBlock": "Code",
        "formatTooltipblockquote": "Zitat",
        "formatTooltipcolor": "Schriftfarbe",
        "formatTooltipbackground": "Hintergundfarbe",
        "formatTooltipfont": "Schriftart",
        "formatTooltipalign": "Ausrichtung",
        "formatTooltipheader": "Schriftgröße",
        "formatTooltipsuper": "Hochgestellt",
        "formatTooltipsub": "Tiefgestellt",
        "formatTooltipordered": "Geordnete Liste",
        "formatTooltipbullet": "Aufzählung",
        "true": "ja",
        "false": "nein",
        "selectEntity": "Entität wählen",
        "filter": "Filter",
        "useFilter": "Filter anwenden",
        "defaultFilter": "Filter zurücksetzen",
        "expand": "erweitern",
        "hide": "verbergen",
        "minimumSelectionError": "Es müssen mindestens {{min}} Einträge ausgewählt werden.",
        "maximumSelectionError": "Es dürfen höchstens {{max}} Einträge ausgewählt werden."
    }
}
