import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Typography,
  Theme,
  Box,
  useTheme,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import CardComponent from '../../../shared/components/DashboardComponent/CardComponent'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded'
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import { User } from '../../../shared/models/Model'
import Env from '../../../shared/config/envConfig'
import formatCurrency from '@3m5/crude-frontend/dist/shared/util/formatCurrency'
import formatDate from '@3m5/crude-frontend/dist/shared/util/formatDate'
import { useTranslation } from 'react-i18next'
import * as settingsActions from '../../../store/settings/settings.actions'
import { useDispatch } from 'react-redux'

interface Props {
  theme: Theme
  user?: User
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '40px',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  [theme.breakpoints.down('md')]: {
    '& td, th': {
      border: 0,
    },
    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },

}))

const DemoDashboard: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const { t } = useTranslation('dashboard')
  const dispatch = useDispatch()

  const [mobileLayout, setMobileLayout] = useState(false)

  const [data, setData] = useState<any>()

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    const restUrl = `${Env.restBase}/api/dashboard`
    fetch(restUrl,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        setData(json)
      })
      .catch(err => console.log(err))
  }, [],
  )

  const rootRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (rootRef.current) {
      const element: HTMLElement = rootRef.current

      setMobileLayout(element.offsetWidth < 600)

      const observer = new ResizeObserver(() => {
        setMobileLayout(element.offsetWidth < 600)
      })
      observer.observe(element)

      return () => {
        observer.disconnect(element)
      }
    }
  }, [rootRef, setMobileLayout])

  return (
    <Grid ref={rootRef} container className={classes.root}>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ backgroundColor: mobileLayout ? 'none' : theme.palette.primary.main, borderRadius: '10px', px: mobileLayout ? 0 : 4, py: mobileLayout ? 0 : 5 }}>
            {props.user && <Typography variant='h4' sx={{ color: mobileLayout ? theme.palette.text.primary : theme.palette.primary.contrastText }}>{t('greeting', { user: 'Kristin Dietze' })}</Typography>}
            <Typography variant='h6' sx={{ color: mobileLayout ? theme.palette.text.primary : theme.palette.primary.contrastText }}>{t('welcomeBack')}</Typography>
          </Box>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            dispatch(settingsActions.setCrudeAppName('customer'))
            const newPath = '/apps/customer/Customer/'
            history.push(newPath)
          }}
        >
          <CardComponent sx={mobileLayout ? { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText } : undefined} height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <GroupRoundedIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('customers')}</Typography>
              <Typography variant='h4'><b>{data?.customerCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            dispatch(settingsActions.setCrudeAppName('order'))
            const newPath = '/apps/order/Order/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <LocalGroceryStoreRoundedIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('orders')}</Typography>
              <Typography variant='h4'><b>{data?.orderCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            dispatch(settingsActions.setCrudeAppName('order'))
            const newPath = '/apps/order/Order/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <LocalAtmRoundedIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('totalValue')}</Typography>
              <Typography variant='h4'><b>{formatCurrency(data?.orderPriceSum || 0)}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid
          item xs={mobileLayout ? 6 : 3} sx={{ cursor: 'pointer' }} onClick={() => {
            dispatch(settingsActions.setCrudeAppName('product'))
            const newPath = '/apps/product/Product/'
            history.push(newPath)
          }}
        >
          <CardComponent height={mobileLayout ? 170 : 240} hoverEffect>
            <Stack alignItems='center' justifyContent='center' height='100%'>
              <Inventory2RoundedIcon sx={{ fontSize: 40, mb: 3 }} />
              <Typography variant='h6'>{t('products')}</Typography>
              <Typography variant='h4'><b>{data?.productCount || 0}</b></Typography>
            </Stack>
          </CardComponent>
        </Grid>
        <Grid item xs={12} container spacing={3} direction={mobileLayout ? 'column-reverse' : 'row'}>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 280}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>{t('newestCustomers')}</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>{t('customerNumber')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('company')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('city')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('country')}</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestCustomer?.map((customer: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>{t('customerNumber')}</Grid>
                                <Grid item xs={6}><Typography>{customer.customerNumber}</Typography></Grid>
                                <Grid item xs={6}>{t('company')}</Grid>
                                <Grid item xs={6}><Typography>{customer.company}</Typography></Grid>
                                <Grid item xs={6}>{t('city')}</Grid>
                                <Grid item xs={6}><Typography>{customer.city}</Typography></Grid>
                                <Grid item xs={6}>{t('country')}</Grid>
                                <Grid item xs={6}><Typography>{customer.country}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell component='th' scope='row'>
                                  {customer.customerNumber}
                                </TableCell>
                                <TableCell>{customer.company}</TableCell>
                                <TableCell>{customer.city}</TableCell>
                                <TableCell>{customer.country}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
          <Grid item xs={mobileLayout ? undefined : 6}>
            <CardComponent padding={mobileLayout ? 0 : undefined} height={mobileLayout ? 'unset' : 280}>
              <Stack spacing={1}>
                <Typography sx={{ padding: mobileLayout ? 2 : 0, paddingBottom: 0 }} variant='h5'>{t('newestOrders')}</Typography>
                <TableContainer>
                  <Table>
                    {!mobileLayout &&
                      <TableHead>
                        <StyledTableRow>
                          <TableCell sx={{ fontWeight: 600 }}>{t('orderNumber')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('orderState')}</TableCell>
                          <TableCell sx={{ fontWeight: 600 }}>{t('deliveredOn')}</TableCell>
                          <TableCell sx={{ fontWeight: 600, textAlign: 'right' }}>{t('price')}</TableCell>
                        </StyledTableRow>
                      </TableHead>}
                    <TableBody>
                      {data && data.newestOrders?.map((order: any, index: number) => (
                        <StyledTableRow
                          key={index}
                        >
                          {mobileLayout ? (
                            <TableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={6}>{t('orderNumber')}</Grid>
                                <Grid item xs={6}><Typography>{order.orderNumber}</Typography></Grid>
                                <Grid item xs={6}>{t('orderState')}</Grid>
                                <Grid item xs={6}><Typography>{t(order.orderState)}</Typography></Grid>
                                <Grid item xs={6}>{t('deliveredOn')}</Grid>
                                <Grid item xs={6}><Typography>{formatDate(new Date(order.deliveredOn).getTime() / 1000)}</Typography></Grid>
                                <Grid item xs={6}>{t('price')}</Grid>
                                <Grid item xs={6}><Typography>{formatCurrency(order.price)}</Typography></Grid>
                              </Grid>
                            </TableCell>)
                            : (
                              <>
                                <TableCell component='th' scope='row'>
                                  {order.orderNumber}
                                </TableCell>
                                <TableCell>{t(order.orderState)}</TableCell>
                                <TableCell>{formatDate(new Date(order.deliveredOn).getTime() / 1000)}</TableCell>
                                <TableCell sx={{ textAlign: 'right' }}>{formatCurrency(order.price)}</TableCell>
                              </>)}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </CardComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default DemoDashboard
