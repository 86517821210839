import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { Drawer, Theme, Box, IconButton, Avatar, Typography } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded'
import HomeIcon from '@mui/icons-material/Home'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import PersonIcon from '@mui/icons-material/Person'
import SettingsIcon from '@mui/icons-material/Settings'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as settingsActions from '../../../store/settings/settings.actions'
import { useDispatch } from 'react-redux'

interface SideNavProps {
  doLogout: () => void
  user: any
  showSidebar: boolean
  setShowSidebar: (value: boolean) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebar: {
      width: 200,
      padding: '20px',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    backIcon: {
      color: 'white',
    },
    iconButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '20px',
    },
  }),
)

const Sidebar: React.FC<SideNavProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const [showSidebar, setShowSidebar] = useState<boolean>(props.showSidebar)

  useEffect(
    () => {
      setShowSidebar(props.showSidebar)
    },
    [props.showSidebar],
  )

  useEffect(
    () => {
      props.setShowSidebar(showSidebar)
    },
    [showSidebar],
  )

  const toggleDrawer =
      () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
          if (
            event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
          ) {
            return
          }

          setShowSidebar(!showSidebar)
        }

  const menuItems = [
    { name: 'customer', path: '/apps/customer/Customer', icon: <GroupRoundedIcon sx={{ marginRight: '15px' }} /> },
    { name: 'employee', path: '/apps/employee/Employee', icon: <PersonIcon sx={{ marginRight: '15px' }} /> },
    { name: 'order', path: '/apps/order/Order', icon: <LocalGroceryStoreIcon sx={{ marginRight: '15px' }} /> },
    { name: 'product', path: '/apps/product/Product', icon: <Inventory2RoundedIcon sx={{ marginRight: '15px' }} /> },
    { name: 'support', path: '/apps/support/SupportTicket', icon: <SupportAgentIcon sx={{ marginRight: '15px' }} /> },
  ]

  return (
    <Drawer
      anchor='left'
      variant='persistent'
      open={showSidebar}
      onClose={toggleDrawer()}
    >
      <Box className={classes.sidebar} role='presentation' sx={{ display: 'flex', alignContent: 'space-between', flexWrap: 'wrap', color: 'white' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <IconButton onClick={() => setShowSidebar(!showSidebar)} className={classes.backIcon}><ArrowBackIosNewIcon /></IconButton>
          </Box>
          <Box
            onClick={() => {
              history.push('/')
              setShowSidebar(false)
            }}
            className={classes.iconButton}
          >
            <HomeIcon sx={{ marginRight: '15px' }} /> <Typography variant='body1'>{t('common:dashboard')}</Typography>
          </Box>

          {menuItems.map((item) => (
            <Box
              key={item.name}
              onClick={() => {
                dispatch(settingsActions.setCrudeAppName(item.name))
                history.push(item.path)
                setShowSidebar(false)
              }}
              className={classes.iconButton}
            >
              {item.icon} <Typography variant='body1'>{t(`common:${item.name}`)}</Typography>
            </Box>
          ))}

        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ marginBottom: '15px' }}>{t('common:loginAs')}</Typography>
          <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Avatar sx={{ marginRight: '15px' }}><img style={{ width: '100%' }} src='/dietze.png' /></Avatar>
            <Typography variant='body1'>Kristin Dietze</Typography>
          </Box>

          <Box
            onClick={() => {
              history.push('/apps/settings')
              props.doLogout()
              setShowSidebar(false)
            }}
            className={classes.iconButton}
          >
            <SettingsIcon sx={{ marginRight: '15px' }} /> <Typography variant='body1'>{t('common:settings')}</Typography>
          </Box>
          <Box
            onClick={() => {
              props.doLogout()
              setShowSidebar(false)
            }}
            className={classes.iconButton}
          >
            <PowerSettingsNewIcon sx={{ marginRight: '15px' }} /> <Typography variant='body1'>{t('common:logout')}</Typography>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Sidebar
