"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getCompleteConfig = function (crudeConfig, localConfig) {
    var _a;
    var completeConfig = crudeConfig;
    var completeTabs = [];
    // TODO auf Theme, etc. erweitern
    // Tabs betrachten
    (_a = crudeConfig.tabs) === null || _a === void 0 ? void 0 : _a.map(function (tab) {
        var _a, _b;
        var localColumns = (_b = (_a = localConfig.tabs) === null || _a === void 0 ? void 0 : _a.find(function (elem) { return elem.entity === tab.entity; })) === null || _b === void 0 ? void 0 : _b.columns;
        var newTab = tab;
        if (localColumns) {
            newTab.columns = localColumns;
        }
        completeTabs.push(newTab);
    });
    completeConfig.tabs = completeTabs;
    return completeConfig;
};
exports.default = getCompleteConfig;
