import React, { useEffect, useState } from 'react'
import { createStore } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { Box, CssBaseline, Stack, Theme, ThemeProvider } from '@mui/material'

import Env from '../../shared/config/envConfig'
import TableActionElement from '../../screens/App/TableActionElement'
import {
  getAppLayout,
  getCrudeAppName,
  getPrefersDarkMode,
  getTheme,
  getUser,
} from '../../store/settings/settings.selectors'
import * as settingsActions from '../../store/settings/settings.actions'
import { setLogo, setUser } from '../../store/settings/settings.actions'
import Settings from '../../screens/App/screens/Settings'
import appConfig from '../../shared/config/appConfig'
import editConfig from '../../shared/config/editConfig'
import { getAppTheme } from '../../shared/util/getAppTheme'

// Import CRUDE
import { crudeRootReducer } from '@3m5/crude-frontend/dist/store/crudeRoot.reducer'
import { CrudeContext, useCrude } from '@3m5/crude-frontend/dist/crudeHooks'
import SnackbarComponent from '../../shared/components/MessageComponents/SnackbarComponent'
import DemoDashboard from './screens/DemoDashboard'
import { License } from '@3m5/crude-frontend/dist/shared/types/licenseTypes'
import getDefaultColor from '../../shared/config/getDefaultColor'
import Login from '../../shared/components/Login'
import { useHistory } from 'react-router-dom'
import Header from '../../shared/components/Navigation/Header'
import { CrudeConfiguration } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const store2 = createStore(crudeRootReducer)

const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const user = useSelector(getUser)
  const darkMode: boolean = useSelector(getPrefersDarkMode)
  const storeAppLayout = useSelector(getAppLayout)
  const storedAppTheme = useSelector(getTheme)
  const crudeAppName = useSelector(getCrudeAppName)

  useEffect(() => {
    const initialTheme = getAppTheme(darkMode, getDefaultColor())
    dispatch(settingsActions.setTheme(initialTheme))
  }, [])

  const [theme, setTheme] = useState<Theme>(getAppTheme(darkMode, getDefaultColor()))

  useEffect(() => {
    const oldMode = localStorage.getItem('darkMode') === 'true'
    setTheme(storedAppTheme)
    store2.getState().crudeStore.darkMode = darkMode
    if (oldMode !== darkMode) {
      localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
      dispatch(setLogo(darkMode ? '/logoDeviDark.png' : '/logoDeviLight.png'))
      location.reload()
    }
  }, [storedAppTheme, darkMode])

  useEffect(() => {
    let pathname = ''
    if (!user) {
      pathname = 'login'
    }
    history.push(`/${pathname}`)
  }, [history, user])

  useEffect(() => {
    const requestHeaders: HeadersInit = new Headers()
    requestHeaders.set('Accept', 'application/json')
    requestHeaders.set('Content-Type', 'application/json')
    fetch(`${Env.restBase}/api/session/user`,
      {
        method: 'GET',
        credentials: 'include',
        headers: requestHeaders,
      })
      .then(res => res.json())
      .then(json => {
        !json.error && dispatch(setUser(json))
      })
      .catch(err => console.log(err))
  }, [])

  const crudeLicense = {
    [License.outgoingReferenceFilters]: {
      available: true,
    },
    [License.standardFilter]: {
      available: true,
    },
    [License.extendedEditForms]: {
      available: true,
    },
    [License.i18n]: {
      available: true,
    },
    [License.extendedNavigation]: {
      available: true,
    },
    [License.richTextEditor]: {
      available: true,
    },
    [License.extendedTable]: {
      available: true,
    },
    [License.configurableTable]: {
      available: true,
    },
    [License.fileUpload]: {
      available: true,
    },
    [License.treeView]: {
      available: true,
    },
    [License.tooltips]: {
      available: true,
    },
    [License.freeJSON]: {
      available: true,
    },
    [License.integratedHTML]: {
      available: true,
    },
    [License.imageCropper]: {
      available: true,
    },
    [License.responsiveViews]: {
      available: true,
    },
  }

  const configurationSupport: CrudeConfiguration = {
    theme: theme,
    path: '/apps/support',
    api: {
      app: 'support', restPath: `${Env.crudeRestBase}`,
    },
    tabs: [
      {
        entity: 'SupportTicket',
      },
      {
        entity: 'Order',
      },
      {
        entity: 'Product',
        hideEntity: true,
      },
      {
        entity: 'ProductType',
        hideEntity: true,
      },
      {
        entity: 'Employee',
        hideEntity: true,
      },
      {
        entity: 'Department',
        hideEntity: true,
      },
    ],
    crudeLicense: crudeLicense,
    appLayout: appConfig(),
    editLayout: editConfig(),
  }

  const configurationCustomer: CrudeConfiguration = {
    theme: theme,
    path: '/apps/customer',
    api: {
      app: 'customer', restPath: `${Env.crudeRestBase}`,
    },
    tabs: [
      {
        entity: 'Customer',
      },
      {
        entity: 'ContactPerson',
      },
      {
        entity: 'Address',
        tabs: [
          {
            sourceEntity: 'Customer',
            sourceColumn: 'billingAddress',
            label: {
              'de-DE': 'Kunden - Rechnungsadresse',
              'en-EN': 'Customer - Billing address',
            },
          },
          {
            sourceEntity: 'Customer',
            sourceColumn: 'shippingAddress',
            label: {
              'de-DE': 'Kunden - Lieferadresse',
              'en-EN': 'Customer - Shipping address',
            },
          },
        ],
      },
      {
        entity: 'Country',
      },
    ],
    crudeLicense: crudeLicense,
    appLayout: appConfig(),
    editLayout: editConfig(),
  }

  const configurationEmployee: CrudeConfiguration = {
    theme: theme,
    path: '/apps/employee',
    api: {
      app: 'employee', restPath: `${Env.crudeRestBase}`,
    },
    tabs: [
      {
        entity: 'Department',
      },
      {
        entity: 'Employee',
      },
      {
        entity: 'Presence',
      },
      {
        entity: 'Salary',
      },
      {
        entity: 'Address',
      },
      {
        entity: 'Country',
      },
    ],
    crudeLicense: crudeLicense,
    appLayout: appConfig(),
    editLayout: editConfig(),
  }
  const configurationOrder: CrudeConfiguration = {
    theme: theme,
    path: '/apps/order',
    api: {
      app: 'order', restPath: `${Env.crudeRestBase}`,
    },
    tabs: [
      {
        entity: 'Order',
      },
      {
        entity: 'Invoice',
      },
      {
        entity: 'Offer',
      },
      {
        entity: 'Communication',
        columns: [
          {
            name: 'offer',
            pinned: true,
          },
          {
            name: 'communicationType',
            pinned: false,
          },
          {
            name: 'subject',
            pinned: false,
          },
          {
            name: 'message',
            pinned: false,
          },
          {
            name: 'employee',
            pinned: false,
          },
          {
            name: 'createdAt',
            pinned: false,
            sort: 'desc',
          },
        ],
      },
      {
        entity: 'OfferProtocolLog',
        columns: [
          {
            name: 'offer',
            pinned: true,
          },
          {
            name: 'actionType',
          },
          {
            name: 'message',
          },
          {
            name: 'paylod',
            hideInTableView: true,
          },
          {
            name: 'createdAt',
            pinned: false,
            sort: 'desc',
          },
        ],
      },
      {
        entity: 'Employee',
        hideEntity: true,
      },
      {
        entity: 'Address',
        hideEntity: true,
      },
      {
        entity: 'Country',
        hideEntity: true,
      },
      {
        entity: 'Salary',
        hideEntity: true,
      },
      {
        entity: 'Department',
        hideEntity: true,
      },
      {
        entity: 'Presence',
        hideEntity: true,
      },
      {
        entity: 'ProductType',
        hideEntity: true,
      },
      {
        entity: 'Product',
        hideEntity: true,
      },
      {
        entity: 'Customer',
        hideEntity: true,
      },
      {
        entity: 'ContactPerson',
        hideEntity: true,
      },
    ],
    crudeLicense: crudeLicense,
    appLayout: appConfig(),
    editLayout: editConfig(),
  }

  const configurationProduct: CrudeConfiguration = {
    theme: theme,
    path: '/apps/product',
    api: {
      app: 'product', restPath: `${Env.crudeRestBase}`,
    },
    tabs: [
      {
        entity: 'Product',
        tabs: [
          {
            sourceEntity: 'ProductProposal',
            sourceColumn: 'proposalProductId',
            label: {
              'de-DE': 'Empfohlene Produkte',
              'en-EN': 'Proposed products',
            },
          },
          {
            sourceEntity: 'ProductProposal',
            sourceColumn: 'productId',
            hideEntity: true,
          },
        ],
      },
      {
        entity: 'ProductType',
      },
    ],
    crudeLicense: crudeLicense,
    appLayout: appConfig(),
    editLayout: editConfig(),
  }

  const [configuration, setConfiguration] = useState(configurationSupport)

  useEffect(() => {
    if (crudeAppName === 'support') {
      setConfiguration({
        ...configurationSupport, appLayout: appConfig(storeAppLayout), theme: storedAppTheme,
      })
    }
    if (crudeAppName === 'customer') {
      setConfiguration({
        ...configurationCustomer, appLayout: appConfig(storeAppLayout), theme: storedAppTheme,
      })
    }
    if (crudeAppName === 'employee') {
      setConfiguration({
        ...configurationEmployee, appLayout: appConfig(storeAppLayout), theme: storedAppTheme,
      })
    }
    if (crudeAppName === 'order') {
      setConfiguration({
        ...configurationOrder, appLayout: appConfig(storeAppLayout), theme: storedAppTheme,
      })
    }
    if (crudeAppName === 'product') {
      setConfiguration({
        ...configurationProduct, appLayout: appConfig(storeAppLayout), theme: storedAppTheme,
      })
    }
  }, [crudeAppName, storeAppLayout, storedAppTheme])

  const logoutUser = () => {
    dispatch(settingsActions.setUser(undefined))
    // window.open(
    //   `${Env.restBase}/logout`,
    // );
    fetch(`${Env.restBase}/api/session/logout`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json',
      },
    })
  }

  window.addEventListener('CrudeLogout', (({ detail }: CustomEvent) => {
    logoutUser()
  }) as EventListener)

  window.addEventListener('CrudeSchema', (({ detail }: CustomEvent) => {
    // console.log('event', detail)
  }) as EventListener)

  window.addEventListener('CrudeEntityChange', (({ detail }: CustomEvent) => {
    // console.log('CrudeEntityChange', detail)
  }) as EventListener)

  const DashboardApp = () => (<DemoDashboard theme={theme} user={user} />)
  const SettingsApp = () => (<Settings user={user} />)

  const crudeContext = useCrude({ appPageProps: configuration, tableActionComponent: TableActionElement })
  const [showPlayground, setShowPlayground] = useState<boolean>(JSON.parse(window.localStorage.getItem('showPlayground')) || false)

  return (
    <CrudeContext.Provider value={crudeContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Stack spacing={2} sx={{ width: showPlayground ? 'calc(100% - 500px)' : '100%' }}>
          {user &&
            <>
              <Header
                setShowPlayground={setShowPlayground}
                user={user}
                doLogout={logoutUser}
              />
              <Box sx={{ width: '100%', padding: '20px' }}>
                <Switch>
                  <Route key='customer' path='/apps/customer' component={crudeContext.Crude} />
                  <Route key='employee' path='/apps/employee' component={crudeContext.Crude} />
                  <Route key='order' path='/apps/order' component={crudeContext.Crude} />
                  <Route key='product' path='/apps/product' component={crudeContext.Crude} />
                  <Route key='support' path='/apps/support' component={crudeContext.Crude} />
                  <Route exact path='/apps/settings' component={SettingsApp} />
                  <Route component={DashboardApp} />
                </Switch>
              </Box>
              {TableActionElement}
            </>}
          <Switch>
            <Route exact path='/login' component={Login} />
          </Switch>
          <SnackbarComponent />
        </Stack>
      </ThemeProvider>
    </CrudeContext.Provider>)
}

export default App
