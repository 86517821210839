{
  "common": {
    "administration": "Administration",
    "projects": "Projects",
    "quickAccess": "VPS quick access",
    "editProfile": "Edit profile",
    "settings": "Settings",
    "logout": "Logout",
    "sendEmail": "Send E-Mail"
  },
  "settings": {
    "username": "Username",
    "email": "E-Mail",
    "name": "Full Name",
    "role": "Role",
    "phone": "Phone",
    "customer": "Customer",
    "changePassword": "Change password",
    "oldPassword": "Old password",
    "newPassword": "New password",
    "confirmPassword": "Confirm password"
  },
  "layout": {
    "playground": "Playground",
    "tabLayout": "Layout",
    "tabTable": "Tables",
    "tabEdit": "Edit",
    "tabRTE": "Text editor",
    "darkMode": "Dark Mode",
    "referenceFilter": "Show reference filter",
    "button": "Button",
    "textfield": "Textfield",
    "embeddedEditHigherMainLevel": "Edit in table embedded main view",
    "embeddedEditFormHigherLevel": "Edit in table embedded sub views",
    "maxTreeViewLevel": "Max tree view layers",
    "maxThumbnails": "Max preview icons",
    "maxTextLines": "Max text lines in tables",
    "maxJumpLevel": "Max link layers",
    "initialPageSize": "Page size",
    "pageSizeOptions": "Page size options",
    "useImageCropper": "User imagecropper",
    "switch": "Switch",
    "checkbox": "Checkbox",
    "boolean": "True / False",
    "enum": "Choose",
    "dropdown": "Dropdown",
    "radio": "Radio",
    "references": "Reference select",
    "popUp": "PopUp",
    "list": "List",
    "autocomplete": "Autocomplete",
    "infoIcon": "Info Icon",
    "deleteIcon": "Delete Icon",
    "disabledInputValue": "Disabled content",
    "hideNumberInputArrows": "Number field",
    "radioColumn": "Horizontal",
    "radioRow": "Vertical",
    "maxRowsPerDialogPage": "Max table lines in popups",
    "maxDropdownSize": "Max elements in dropdowns",
    "minRowsMultiline": "Min lines in multiline textfields",
    "leftHeader": "Side navigation"
  }
}
