import { EditLayout, StringLayout } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const editConfig = () => {
  const config: EditLayout = {
    version: 1,
    entities: [
      {
        entity: 'Product',
        elements: [
          {
            name: 'productType',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'name',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'lastUpdate',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'nettoPrice',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'imageIds',
            editTab: 'files',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'description',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'fileIds',
            editTab: 'files',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            description: {
              'de-DE': 'Hier kommen die wesentlichen Inhalte rein',
              'en-US': 'Main content',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                title: {},
                name: 'productData',
                initialOpen: true,
                closable: false,
              },
            ],
          },
          {
            title: {
              'de-DE': 'Dateien',
              'en-US': 'Files',
            },
            name: 'files',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 1,
                  },
                },
                name: 'additional',
                title: {
                  'de-DE': 'Anhänge',
                  'en-US': 'Additional',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Customer',
        elements: [
          {
            name: 'id',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'customerNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'customerType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'contactType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'firstName',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'lastName',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'company',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'taxId',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'billingAddress',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'shippingAddress',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'email',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'phoneNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'address',
                title: {
                  'de-DE': 'Adresse',
                  'en-US': 'Adresse',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 4,
                    to: 5,
                  },
                },
                name: 'contact',
                title: {
                  'de-DE': 'Kontakt',
                  'en-US': 'Contact',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Address',
        elements: [
          {
            name: 'address',
            position: {
              horizontal: {
                from: 0,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'zip',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'city',
            position: {
              horizontal: {
                from: 1,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'country',
            position: {
              horizontal: {
                from: 0,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'Order',
        elements: [
          {
            name: 'orderNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'createdOn',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'orderState',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'deliveredOn',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'employee',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'ContactPerson',
        elements: [
          {
            name: 'customer',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'salutation',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'academicTitle',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'firstName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'lastName',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'callbackFrom',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'callbackTo',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'phoneNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'email',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'socialMedia',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 3,
                    to: 5,
                  },
                },
                name: 'contact',
                title: {
                  'de-DE': 'Kontakt',
                  'en-US': 'Contact',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Presence',
        elements: [
          {
            name: 'employee',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'date',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'checkIn',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'checkOut',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'remarks',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'Employee',
        elements: [
          {
            name: 'department',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'role',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'salutation',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'academicTitle',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'firstName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'lastName',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'birthday',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'address',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'phoneNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'email',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'userName',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'isActive',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'availableFrom',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'availableTo',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'hiringDate',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
          {
            name: 'dischargeDate',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 8,
                to: 8,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: false,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 4,
                    to: 5,
                  },
                },
                name: 'contact',
                title: {
                  'de-DE': 'Kontakt',
                  'en-US': 'Contact',
                },
                initialOpen: false,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 6,
                    to: 8,
                  },
                },
                name: 'intern',
                title: {
                  'de-DE': 'Intern',
                  'en-US': 'Intern',
                },
                initialOpen: false,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'SupportTicket',
        elements: [
          {
            name: 'supportCategory',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'supportStatus',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'supportPrio',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'createdOn',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'employee',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'order',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'product',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'title',
            position: {
              horizontal: {
                from: 0,
                to: 2,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'description',
            layout: StringLayout.multiline,
            position: {
              horizontal: {
                from: 0,
                to: 2,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {
                  'de-DE': 'Allgemein',
                  'en-US': 'General',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 2,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'order',
                title: {
                  'de-DE': 'Bestellung',
                  'en-US': 'Order',
                },
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 3,
                    to: 4,
                  },
                },
                name: 'details',
                title: {
                  'de-DE': 'Details',
                  'en-US': 'Details',
                },
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
      {
        entity: 'Offer',
        elements: [
          {
            name: 'offerNumber',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'offerState',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'createdOn',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'chance',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'contact',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'employee',
            position: {
              horizontal: {
                from: 2,
                to: 2,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'description',
            position: {
              horizontal: {
                from: 0,
                to: 2,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 2,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'Communication',
        elements: [
          {
            name: 'offer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'employee',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'communicationType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'createdAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'subject',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'message',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'OfferProtocolLog',
        elements: [
          {
            name: 'offer',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'createdAt',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'actionType',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'message',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'payload',
            position: {
              horizontal: {
                from: 0,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 2,
                  },
                },
                name: 'general',
                title: {},
                initialOpen: true,
                closable: false,
              },
            ],
          },
        ],
      },
      {
        entity: 'Invoice',
        elements: [
          {
            name: 'order',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'invoiceType',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'invoiceState',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'createdOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 0,
                to: 0,
              },
            },
          },
          {
            name: 'invoiceDate',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 1,
                to: 1,
              },
            },
          },
          {
            name: 'dueDate',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 2,
                to: 2,
              },
            },
          },
          {
            name: 'paidOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 3,
                to: 3,
              },
            },
          },
          {
            name: 'deliveredOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'deliveryMethod',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 4,
                to: 4,
              },
            },
          },
          {
            name: 'netPrice',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'taxAmount',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'grossPrice',
            editTab: 'details',
            position: {
              horizontal: {
                from: 0,
                to: 0,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
          {
            name: 'friendlyReminderDeliveredOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 5,
                to: 5,
              },
            },
          },
          {
            name: 'firstReminderDeliveredOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 6,
                to: 6,
              },
            },
          },
          {
            name: 'secondReminderDeliveredOn',
            editTab: 'details',
            position: {
              horizontal: {
                from: 1,
                to: 1,
              },
              vertical: {
                from: 7,
                to: 7,
              },
            },
          },
        ],
        editTabs: [
          {
            title: {
              'de-DE': 'Details',
              'en-US': 'Details',
            },
            name: 'details',
            formGroups: [
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 0,
                    to: 3,
                  },
                },
                title: {
                  'de-DE': 'Rechnungsdaten',
                  'en-US': 'Invoice data',
                },
                name: 'invoice',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 1,
                  },
                  vertical: {
                    from: 4,
                    to: 4,
                  },
                },
                title: {
                  'de-DE': 'Lieferdaten',
                  'en-US': 'Delivery data',
                },
                name: 'delivery',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 0,
                    to: 0,
                  },
                  vertical: {
                    from: 5,
                    to: 7,
                  },
                },
                title: {
                  'de-DE': 'Preisinfomationen',
                  'en-US': 'Price information',
                },
                name: 'price',
                initialOpen: true,
                closable: true,
              },
              {
                position: {
                  horizontal: {
                    from: 1,
                    to: 1,
                  },
                  vertical: {
                    from: 5,
                    to: 7,
                  },
                },
                title: {
                  'de-DE': 'Mahnungsdaten',
                  'en-US': 'Reminder data',
                },
                name: 'reminder',
                initialOpen: true,
                closable: true,
              },
            ],
          },
        ],
      },
    ],
  }
  return config
}
export default editConfig
