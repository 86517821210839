"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Method = exports.PinPosition = exports.TableTextAlignment = exports.TextType = exports.DocumentType = exports.VideoType = exports.ImageType = exports.DataViewType = exports.DataDisplayMode = exports.Order = exports.DisplayMode = exports.ScreenDesign = exports.Permission = void 0;
var Permission;
(function (Permission) {
    Permission["Default"] = "Default";
    Permission["ReadOnly"] = "ReadOnly";
    Permission["Hidden"] = "Hidden";
})(Permission = exports.Permission || (exports.Permission = {}));
var ScreenDesign;
(function (ScreenDesign) {
    ScreenDesign["desktop"] = "desktop";
    ScreenDesign["tablet"] = "tablet";
    ScreenDesign["mobile"] = "mobile";
})(ScreenDesign = exports.ScreenDesign || (exports.ScreenDesign = {}));
var DisplayMode;
(function (DisplayMode) {
    DisplayMode["table"] = "table";
    DisplayMode["edit"] = "edit,";
})(DisplayMode = exports.DisplayMode || (exports.DisplayMode = {}));
var Order;
(function (Order) {
    Order["asc"] = "asc";
    Order["desc"] = "desc";
})(Order = exports.Order || (exports.Order = {}));
var DataDisplayMode;
(function (DataDisplayMode) {
    DataDisplayMode["list"] = "list";
    DataDisplayMode["edit"] = "edit";
    DataDisplayMode["view"] = "view";
})(DataDisplayMode = exports.DataDisplayMode || (exports.DataDisplayMode = {}));
var DataViewType;
(function (DataViewType) {
    DataViewType["referenceView"] = "referenceView";
    DataViewType["fullView"] = "fullView";
    DataViewType["simpleView"] = "simpleView";
})(DataViewType = exports.DataViewType || (exports.DataViewType = {}));
var ImageType;
(function (ImageType) {
    ImageType["bmp"] = "bmp";
    ImageType["gif"] = "gif";
    ImageType["ico"] = "ico";
    ImageType["jpeg"] = "jpeg";
    ImageType["jpg"] = "jpg";
    ImageType["png"] = "png";
    ImageType["svg"] = "svg";
    ImageType["tiff"] = "tiff";
    ImageType["tif"] = "tif";
    ImageType["webp"] = "webp";
})(ImageType = exports.ImageType || (exports.ImageType = {}));
var VideoType;
(function (VideoType) {
    VideoType["avi"] = "avi";
    VideoType["mp4"] = "mp4";
    VideoType["mpeg"] = "mpeg";
    VideoType["ogv"] = "ogv";
    VideoType["ts"] = "ts";
    VideoType["webm"] = "webm";
    VideoType["3gp"] = "3gp";
    VideoType["3g2"] = "3g2";
})(VideoType = exports.VideoType || (exports.VideoType = {}));
var DocumentType;
(function (DocumentType) {
    DocumentType["doc"] = "doc";
    DocumentType["docx"] = "docx";
    DocumentType["odp"] = "odp";
    DocumentType["ods"] = "ods";
    DocumentType["odt"] = "odt";
    DocumentType["pdf"] = "pdf";
    DocumentType["ppt"] = "ppt";
    DocumentType["pptx"] = "pptx";
    DocumentType["rtf"] = "rtf";
    DocumentType["xls"] = "xls";
    DocumentType["xlsx"] = "xlsx";
})(DocumentType = exports.DocumentType || (exports.DocumentType = {}));
var TextType;
(function (TextType) {
    TextType["csv"] = "csv";
    TextType["htm"] = "htm";
    TextType["html"] = "html";
    TextType["json"] = "json";
    TextType["txt"] = "txt";
    TextType["xhtml"] = "xhtml";
    TextType["xml"] = "xml";
})(TextType = exports.TextType || (exports.TextType = {}));
var TableTextAlignment;
(function (TableTextAlignment) {
    TableTextAlignment["left"] = "left";
    TableTextAlignment["center"] = "center";
    TableTextAlignment["right"] = "right";
})(TableTextAlignment = exports.TableTextAlignment || (exports.TableTextAlignment = {}));
var PinPosition;
(function (PinPosition) {
    PinPosition["left"] = "left";
    PinPosition["right"] = "right";
})(PinPosition = exports.PinPosition || (exports.PinPosition = {}));
var Method;
(function (Method) {
    Method["create"] = "create";
    Method["update"] = "update";
    Method["delete"] = "delete";
})(Method = exports.Method || (exports.Method = {}));
