"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getScreenDesign = exports.getTemporaryFetch = exports.getTemporaryJoinData = exports.getLoadFilterData = exports.getCrudeFilter = exports.getCrudeLicense = exports.getReloadListEntity = exports.getMessages = exports.getShowCollapse = exports.getShowDialog = exports.getLastActiveEntity = exports.getActiveEntity = exports.getActiveTab = exports.getDataDisplayMode = exports.getTableFilterSettings = exports.getCrudeTabs = exports.getCrudePath = exports.getDarkMode = exports.getEditLayout = exports.darkMode = exports.getAppLayout = exports.getCrudeConfiguration = exports.getCrudeLabels = exports.getCrudeSchema = exports.getTheme = exports.getAvailableLanguages = exports.getLanguage = exports.getUser = void 0;
var reselect_1 = require("reselect");
exports.getUser = reselect_1.createSelector(function (state) { return state.crudeStore.user; }, function (state) { return state; });
exports.getLanguage = reselect_1.createSelector(function (state) { return state.crudeStore.language; }, function (state) { return state; });
exports.getAvailableLanguages = reselect_1.createSelector(function (state) { return state.crudeStore.availableLanguages; }, function (state) { return state; });
exports.getTheme = reselect_1.createSelector(function (state) { return state.crudeStore.theme; }, function (state) { return state; });
exports.getCrudeSchema = reselect_1.createSelector(function (state) { return state.crudeStore.crudeSchema; }, function (state) { return state; });
exports.getCrudeLabels = reselect_1.createSelector(function (state) { return state.crudeStore.crudeLabels; }, function (state) { return state; });
exports.getCrudeConfiguration = reselect_1.createSelector(function (state) { return state.crudeStore.crudeConfiguration; }, function (state) { return state; });
exports.getAppLayout = reselect_1.createSelector(function (state) { return state.crudeStore.appLayout; }, function (state) { return state; });
exports.darkMode = reselect_1.createSelector(function (state) { return state.crudeStore.darkMode; }, function (state) { return state; });
exports.getEditLayout = reselect_1.createSelector(function (state) { return state.crudeStore.editLayout; }, function (state) { return state; });
exports.getDarkMode = reselect_1.createSelector(function (state) { return state.crudeStore.darkMode; }, function (state) { return state; });
exports.getCrudePath = reselect_1.createSelector(function (state) { return state.crudeStore.crudePath; }, function (state) { return state; });
exports.getCrudeTabs = reselect_1.createSelector(function (state) { return state.crudeStore.crudeTabs; }, function (state) { return state; });
exports.getTableFilterSettings = reselect_1.createSelector(function (state) { return state.crudeStore.tableFilterSettings; }, function (state) { return state; });
exports.getDataDisplayMode = reselect_1.createSelector(function (state) { return state.crudeStore.dataDisplayMode; }, function (state) { return state; });
exports.getActiveTab = reselect_1.createSelector(function (state) { return state.crudeStore.activeTab; }, function (state) { return state; });
exports.getActiveEntity = reselect_1.createSelector(function (state) { return state.crudeStore.activeEntity; }, function (state) { return state; });
exports.getLastActiveEntity = reselect_1.createSelector(function (state) { return state.crudeStore.lastActiveEntity; }, function (state) { return state; });
exports.getShowDialog = reselect_1.createSelector(function (state) { return state.crudeStore.showDialog; }, function (state) { return state; });
exports.getShowCollapse = reselect_1.createSelector(function (state) { return state.crudeStore.showCollapse; }, function (state) { return state; });
exports.getMessages = reselect_1.createSelector(function (state) { return state.crudeStore.messages; }, function (state) { return state; });
exports.getReloadListEntity = reselect_1.createSelector(function (state) { return state.crudeStore.reloadListEntity; }, function (state) { return state; });
exports.getCrudeLicense = reselect_1.createSelector(function (state) { return state.crudeStore.crudeLicense; }, function (state) { return state; });
exports.getCrudeFilter = reselect_1.createSelector(function (state) { return state.crudeStore.crudeFilter; }, function (state) { return state; });
exports.getLoadFilterData = reselect_1.createSelector(function (state) { return state.crudeStore.loadFilterData; }, function (state) { return state; });
exports.getTemporaryJoinData = reselect_1.createSelector(function (state) { return state.crudeStore.temporaryJoinData; }, function (state) { return state; });
exports.getTemporaryFetch = reselect_1.createSelector(function (state) { return state.crudeStore.temporaryFetch; }, function (state) { return state; });
exports.getScreenDesign = reselect_1.createSelector(function (state) { return state.crudeStore.screenDesign; }, function (state) { return state; });
