"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToolbarListElement = exports.ToolbarFontStyleElement = exports.ToolbarAdditionalElement = exports.ToolbarStyleElement = exports.ForeignEntityLayout = exports.EnumLayout = exports.BooleanLayout = exports.StringLayout = exports.JoinEntityLayout = exports.RadioDirection = exports.LanguageType = void 0;
var LanguageType;
(function (LanguageType) {
    LanguageType["de-DE"] = "de-DE";
    LanguageType["en-US"] = "en-US";
    LanguageType["it-IT"] = "it-IT";
    LanguageType["es-ES"] = "es-ES";
    LanguageType["fr-FR"] = "fr-FR";
    LanguageType["tr-TR"] = "tr-TR";
    LanguageType["ru-RU"] = "ru-RU";
    LanguageType["pl-PL"] = "pl-PL";
    LanguageType["pt-PT"] = "pt-PT";
    LanguageType["sv-SE"] = "sv-SE";
    LanguageType["uk-UA"] = "uk-UA";
    LanguageType["ro-RO"] = "ro-RO";
    LanguageType["no-NO"] = "no-NO";
    LanguageType["nl-NL"] = "nl-NL";
    LanguageType["hu-HU"] = "hu-HU";
    LanguageType["hr-HR"] = "hr-HR";
    LanguageType["fi-FI"] = "fi-FI";
    LanguageType["el-GR"] = "el-GR";
    LanguageType["da-DK"] = "da-DK";
    LanguageType["cs-CZ"] = "cs-CZ";
})(LanguageType = exports.LanguageType || (exports.LanguageType = {}));
var RadioDirection;
(function (RadioDirection) {
    RadioDirection["row"] = "row";
    RadioDirection["column"] = "column";
})(RadioDirection = exports.RadioDirection || (exports.RadioDirection = {}));
var JoinEntityLayout;
(function (JoinEntityLayout) {
    JoinEntityLayout["joinEntity"] = "joinEntity";
    JoinEntityLayout["referenceView"] = "referenceView";
    JoinEntityLayout["multiSelectDropdown"] = "multiSelectDropdown";
    JoinEntityLayout["multiSelectAutoComplete"] = "multiSelectAutoComplete";
})(JoinEntityLayout = exports.JoinEntityLayout || (exports.JoinEntityLayout = {}));
var StringLayout;
(function (StringLayout) {
    StringLayout["singleline"] = "singleline";
    StringLayout["multiline"] = "multiline";
})(StringLayout = exports.StringLayout || (exports.StringLayout = {}));
var BooleanLayout;
(function (BooleanLayout) {
    BooleanLayout["checkbox"] = "checkbox";
    BooleanLayout["switch"] = "switch";
})(BooleanLayout = exports.BooleanLayout || (exports.BooleanLayout = {}));
var EnumLayout;
(function (EnumLayout) {
    EnumLayout["radio"] = "radio";
    EnumLayout["dropdown"] = "dropdown";
})(EnumLayout = exports.EnumLayout || (exports.EnumLayout = {}));
var ForeignEntityLayout;
(function (ForeignEntityLayout) {
    ForeignEntityLayout["popUp"] = "popUp";
    ForeignEntityLayout["list"] = "list";
    ForeignEntityLayout["autocomplete"] = "autocomplete";
})(ForeignEntityLayout = exports.ForeignEntityLayout || (exports.ForeignEntityLayout = {}));
var ToolbarStyleElement;
(function (ToolbarStyleElement) {
    ToolbarStyleElement["bold"] = "bold";
    ToolbarStyleElement["italic"] = "italic";
    ToolbarStyleElement["underline"] = "underline";
    ToolbarStyleElement["strike"] = "strike";
    ToolbarStyleElement["codeBlock"] = "code-block";
    ToolbarStyleElement["blockquote"] = "blockquote";
    ToolbarStyleElement["clean"] = "clean";
})(ToolbarStyleElement = exports.ToolbarStyleElement || (exports.ToolbarStyleElement = {}));
var ToolbarAdditionalElement;
(function (ToolbarAdditionalElement) {
    ToolbarAdditionalElement["color"] = "color";
    ToolbarAdditionalElement["background"] = "background";
    ToolbarAdditionalElement["font"] = "font";
    ToolbarAdditionalElement["image"] = "image";
    ToolbarAdditionalElement["header"] = "header";
})(ToolbarAdditionalElement = exports.ToolbarAdditionalElement || (exports.ToolbarAdditionalElement = {}));
var ToolbarFontStyleElement;
(function (ToolbarFontStyleElement) {
    ToolbarFontStyleElement["sub"] = "sub";
    ToolbarFontStyleElement["super"] = "super";
})(ToolbarFontStyleElement = exports.ToolbarFontStyleElement || (exports.ToolbarFontStyleElement = {}));
var ToolbarListElement;
(function (ToolbarListElement) {
    ToolbarListElement["ordered"] = "ordered";
    ToolbarListElement["bullet"] = "bullet";
})(ToolbarListElement = exports.ToolbarListElement || (exports.ToolbarListElement = {}));
