"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var configurationTypes_1 = require("../shared/types/configurationTypes");
var getDefaultAppConfig = function () {
    var defaultAppConfig = {
        version: 1,
        maxTreeViewLevel: 3,
        maxThumbnails: 5,
        useImageCropper: true,
        maxDropdownSize: 2,
        initialPageSize: 15,
        maxRowsPerDialogPage: 10,
        maxResponsiveTableColumns: 3,
        maxForeignReferenceJumpLevel: 3,
        maxTextLinesInTableView: 3,
        showReferenceFilter: true,
        showStandardFilter: true,
        showJoinEntityFilter: true,
        embeddedEditFormMainLevel: false,
        embeddedEditFormHigherLevel: true,
        tableTemplates: [{
                entity: 'Product',
                column: 'nettoPrice',
                template: 'formatCurrency({{data:nettoPrice}})',
            }],
        entityTemplates: [
            {
                name: 'OrderingHasProduct',
                template: '<span style="color:grey;">t({{data:state}})</span>&nbsp;{{data:orderingNumber}}&nbsp;{{joinContext:amount}}',
                onlyPositiveBooleans: false,
            },
        ],
        globalInputLayouts: {
            boolean: configurationTypes_1.BooleanLayout.switch,
            enum: configurationTypes_1.EnumLayout.dropdown,
            foreignEntity: configurationTypes_1.ForeignEntityLayout.popUp,
            string: configurationTypes_1.StringLayout.singleline,
        },
        button: {
            type: 'outlined',
        },
        radio: {
            direction: configurationTypes_1.RadioDirection.row,
        },
        textfield: {
            readOnlyPlainText: true,
            type: 'outlined',
            hideNumberInputArrows: false,
            margin: 'none',
            minRows: 3,
            deleteIcon: 'delete',
            tooltipIcon: 'helpOutline',
        },
        elements: [
            {
                id: 'tabs',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: {
                    horizontal: {
                        from: 0,
                        to: 11,
                    },
                    vertical: {
                        from: 0,
                        to: 0,
                    },
                },
            },
            {
                id: 'search',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                position: {
                    horizontal: {
                        from: 0,
                        to: 1,
                    },
                    vertical: {
                        from: 1,
                        to: 1,
                    },
                },
            },
            {
                id: 'filter',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                position: {
                    horizontal: {
                        from: 2,
                        to: 10,
                    },
                    vertical: {
                        from: 1,
                        to: 1,
                    },
                },
            },
            {
                id: 'addIcon',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                position: {
                    horizontal: {
                        from: 11,
                        to: 11,
                    },
                    vertical: {
                        from: 1,
                        to: 1,
                    },
                },
            },
            {
                id: 'data',
                justifyContent: 'space-between',
                alignItems: 'center',
                position: {
                    horizontal: {
                        from: 0,
                        to: 11,
                    },
                    vertical: {
                        from: 2,
                        to: 2,
                    },
                },
            },
        ],
    };
    return defaultAppConfig;
};
exports.default = getDefaultAppConfig;
