import { AppLayout, BooleanLayout, EnumLayout, ForeignEntityLayout, RadioDirection, ToolbarStyleElement, ToolbarListElement, ToolbarFontStyleElement, ToolbarAdditionalElement } from '@3m5/crude-frontend/dist/shared/types/configurationTypes'

const appConfig = (appLayout?: any) => {
  const config: AppLayout = {
    version: 1,
    maxTreeViewLevel: appLayout?.treeViewLevel || 3,
    maxThumbnails: appLayout?.maxThumbnails || 3,
    useImageCropper: appLayout?.useImageCropper || false,
    maxDropdownSize: appLayout?.maxDropdownSize || 50,
    maxTextLinesInTableView: appLayout?.maxTextLinesInTableView || 3,
    maxRowsPerDialogPage: appLayout?.maxRowsPerDialogPage || 15,
    rowsPerPageOptions: appLayout?.rowsPerPageOptions || [10, 15, 30, 60],
    initialPageSize: appLayout?.initialRowsPerPage || 10,
    showReferenceFilter: appLayout?.referenceFilter || false,
    showStandardFilter: appLayout?.standardFilter || false,
    maxForeignReferenceJumpLevel: appLayout?.maxJumpLevel || 3,
    embeddedEditFormMainLevel: appLayout?.embeddedEditFormMainLevel || false,
    embeddedEditFormHigherLevel: appLayout?.embeddedEditFormHigherLevel || false,
    button: {
      type: appLayout?.buttonStyle || 'outlined',
    },
    globalInputLayouts: {
      toolbar: appLayout?.globalToolbar || [
        ToolbarStyleElement.bold, ToolbarStyleElement.italic, ToolbarStyleElement.underline, ToolbarStyleElement.strike,
        ToolbarFontStyleElement.sub, ToolbarFontStyleElement.super,
        ToolbarListElement.ordered, ToolbarListElement.bullet,
        ToolbarAdditionalElement.header,
        ToolbarAdditionalElement.color, ToolbarAdditionalElement.background, ToolbarAdditionalElement.font, ToolbarAdditionalElement.align,
      ],
      boolean: appLayout?.booleanLayout || BooleanLayout.checkbox,
      enum: appLayout?.enumLayout || EnumLayout.dropdown,
      foreignEntity: appLayout?.foreignEntityLayout || ForeignEntityLayout.popUp,
    },
    radio: {
      direction: appLayout?.radioDirection || RadioDirection.row,
    },
    tableTemplates: [{
      entity: 'Product',
      column: 'nettoPrice',
      template: 'formatCurrency({{data:nettoPrice}})',
    }],
    textfield: {
      type: appLayout?.textfieldStyle || 'outlined',
      readOnlyPlainText: appLayout?.readOnlyPlainText || false,
      hideNumberInputArrows: appLayout?.hideNumberInputArrows || false,
      margin: 'none',
      tooltipIcon: appLayout?.infoIcon || 'info',
      deleteIcon: appLayout?.deleteIcon || 'delete',
      minRows: appLayout?.minRowsMultiline || 3,
    },
    format: {
      date: {
        'de-DE': 'dd.MM.yyyy',
        'en-US': 'dd/MM/yyyy',
      },
      time: {
        'de-DE': 'HH:mm',
        'en-US': 'HH:mm',
      },
    },
    elements:
      [
        {
          id: 'tabs',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 0,
              to: 11,
            },
            vertical: {
              from: 0,
              to: 0,
            },
          },
        },
        {
          id: 'addIcon',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 11,
              to: 11,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'search',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: {
            horizontal: {
              from: 0,
              to: 1,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'filter',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          position: {
            horizontal: {
              from: 2,
              to: 10,
            },
            vertical: {
              from: 1,
              to: 1,
            },
          },
        },
        {
          id: 'data',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: {
            horizontal: {
              from: 0,
              to: 11,
            },
            vertical: {
              from: 2,
              to: 2,
            },
          },
        },
      ],
  }
  return config
}
export default appConfig
