{
    "crude": {
        "search": "Search",
        "actions": "Actions",
        "nextPage": "Next page",
        "lastPage": "Last page",
        "back": "Back",
        "empty": "empty",
        "edit": "Edit",
        "delete": "Delete",
        "save": "Save",
        "success": "Saved successfully",
        "invalidDataFields": "the following data fields are invalid:  ",
        "create": "create",
        "data": "data",
        "view": "show",
        "cancel": "cancel",
        "noContent": "no entries",
        "resetFilter": "Reset filter to show more!",
        "createOwnTextField": "Create own textfield",
        "textFieldName": "Label",
        "textFieldValue": "Value",
        "insertTextFieldLabel": "Please insert name",
        "deleteTextField": "delete",
        "entries": "entries",
        "entry": "entry",
        "tabDetail": "Details",
        "entity": "Entity",
        "of": "of",
        "uploadNewFile": "Upload new file",
        "upload": "upload",
        "uploadErrorExtension": "Upload error - wrong extension",
        "confirm": "Ok",
        "deleteFileQuestion": "Do you really want to delete this file? The operation cannot be undone.",
        "deleteFileQuestionTitle": "Attention!",
        "deleteEntityQuestion": "Do you really want to delete this entry? The operation cannot be undone.",
        "deleteEntityQuestionTitle": "Attention!",
        "errorLargeFileUpload": "Filesize too large.",
        "useCroppedImage": "Use image",
        "useOriginalImage": "Use origin",
        "available": "available",
        "searchColumn": "Search column",
        "manageColumns": "Manage columns",
        "hideColumn": "Hide column",
        "pinToRight": "Pin to right",
        "pinToLeft": "Pin to left",
        "sortAsc": "Sort ASC",
        "sortDesc": "Sort DESC",
        "hideAll": "Hide all",
        "showAll": "Show all",
        "preview": "Preview",
        "title": "Title",
        "description": "Description",
        "tooltip": "Tooltip",
        "sort": "Sort",
        "settings": "Settings",
        "incomingReference": "Reference",
        "language": "Language",
        "deleteTabQuestionTitle": "Attention!",
        "deleteTabQuestion": "Do you really want to delete this tab? All settings for this tab will be reset!",
        "deleteElementQuestionTitle": "Attention!",
        "deleteElementQuestion": "Do you really want to delete this item? All settings for this column will be reset!",
        "configurationEditorDescriptionStringLayout": "In this column, three variants can be selected for entering the text:",
        "configurationEditorDescriptionEnumLayout": "Two variants can be selected for the selection of the elements in this column:",
        "configurationEditorDescriptionBooleanLayout": "Two variants can be selected for the selection of the elements in this column:",
        "configurationEditorDescriptionForeignEntityLayout": "Two variants can be selected for the selection of the elements in this column:",
        "stringLayoutsingleline": "Singleline",
        "stringLayoutmultiline": "Multiline",
        "stringLayouteditor": "RichTextEditor",
        "enumLayoutradio": "Radiobutton",
        "enumLayoutdropdown": "Dropdown",
        "booleanLayoutcheckbox": "Checkbox",
        "booleanLayoutswitch": "Switch",
        "foreignEntityLayoutpopUp": "PopUp",
        "foreignEntityLayoutautocomplete": "Dropdown",
        "foreignEntityLayoutlist": "List",
        "formatTooltipbold": "Bold",
        "formatTooltipitalic": "Italic",
        "formatTooltipunderline": "Underline",
        "formatTooltipstrike": "Strike",
        "formatTooltipclean": "Delete format",
        "formatTooltipcodeBlock": "Code",
        "formatTooltipblockquote": "Blockquote",
        "formatTooltipcolor": "Color",
        "formatTooltipbackground": "Background",
        "formatTooltipfont": "Font",
        "formatTooltipalign": "Align",
        "formatTooltipheader": "Fontsize",
        "formatTooltipsuper": "Superscript",
        "formatTooltipsub": "Subscript",
        "formatTooltipordered": "Ordered list",
        "formatTooltipbullet": "Bullets",
        "true": "true",
        "false": "false",
        "selectEntity": "Select entity",
        "filter": "Filter",
        "useFilter": "Use filter",
        "defaultFilter": "Reset filter",
        "expand": "expand",
        "hide": "hide",
        "minimumSelectionError": "At least {{min}} entries must be selected.",
        "maximumSelectionError": "A maximum of {{max}} entries may be selected."
    }
}
